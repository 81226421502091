import { useState } from 'react'
import {
  faBriefcase,
  faCheck,
  faCoins,
  faMegaphone,
  faPuzzlePiece,
} from '@fortawesome/pro-light-svg-icons'
import {
  faCalendarAlt,
  faCodeBranch,
  faComment,
  faEdit,
  faEnvelope,
  faEyeSlash,
  faFileExport,
  faHistory,
  faIcons,
  faInfoCircle,
  faPencilAlt,
  faSignInAlt,
  faStar,
  faTrashAlt,
  faUserFriends,
  faUserPlus,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { filter } from 'lodash'

export const allocatorsMenus = (tag) => [
  {
    text: 'Company Details',
    link: `/companies/allocator/${tag.company.companyId}`,
    func: 2,
    icon: faInfoCircle,
  },
  {
    text: 'Invite New User',
    link: '#',
    func: 3,
    icon: faUserPlus,
  },
  // {
  //   text: "Send Meeting Request",
  //   icon: faUserPlus,
  //   func: 4,
  // },
  {
    text: 'Delete Company',
    icon: faTrashAlt,
    func: 14,
  },
]

export const cfnMenus = (tag) => [
  {
    text: 'Company Details',
    link: `/companies/cfn/${tag.company.companyId}`,
    func: 2,
    icon: faInfoCircle,
  },
  {
    text: 'Invite New User',
    link: '#',
    func: 3,
    icon: faUserPlus,
  },
  {
    text: 'Delete Company',
    icon: faTrashAlt,
    func: 14,
  },
]

export const fundMenus = (tag) => [
  {
    text: 'Company Details',
    link: `/companies/fund/${tag.companyId}`,
    func: 2,
    icon: faInfoCircle,
  },
  {
    text: 'Invite New User',
    link: '#',
    func: 3,
    icon: faUserPlus,
  },
  // {
  //   text: "Send Meeting Request",
  //   func: 4,
  //   icon: faUserPlus,
  // },
  {
    text: 'Delete Company',
    icon: faTrashAlt,
    func: 14,
  },
]

export const serviceProviderMenus = (tag) => [
  {
    text: 'Company Details',
    link: `/companies/serviceprovider/${tag.companyId}`,
    func: 2,
    icon: faInfoCircle,
  },
  {
    text: 'Invite New User',
    link: '#',
    func: 3,
    icon: faUserPlus,
  },
  // {
  //   text: "Send Meeting Request",
  //   func: 4,
  //   icon: faUserPlus,
  // },
  {
    text: 'Delete Company',
    icon: faTrashAlt,
    func: 14,
  },
]

export const sponsorContentEventMenus = (tag) => [
  {
    text: 'Edit Content',
    func: 10,
    icon: faEdit,
  },
  {
    text: 'Delete Content',
    func: 11,
    icon: faTrashAlt,
  },
]

export const sponsorContentMenus = (tag) => [
  {
    text: 'Edit Content',
    func: 12,
    icon: faEdit,
  },
  {
    text: 'Delete Content',
    func: 13,
    icon: faTrashAlt,
  },
]

export const mediaMenus = (tag) => [
  {
    text: 'Company Details',
    link: `/companies/media/${tag.companyId}`,
    func: 2,
    icon: faInfoCircle,
  },
  {
    text: 'Invite New User',
    link: '#',
    func: 3,
    icon: faUserPlus,
  },
  // {
  //   text: "Send Meeting Request",
  //   func: 4,
  //   icon: faUserPlus,
  // },
  {
    text: 'Delete Company',
    icon: faTrashAlt,
    func: 14,
  },
  // {
  //   text: "Split Schedule",
  //   func: 8,
  //   icon: faCodeBranch,
  // },
]

export const summitFundMenus = (tag) => [
  {
    text: 'Details',
    link: `/summits/${tag.eventId}/attendees/funds/${tag.company.name}`,

    func: 2,
    icon: faInfoCircle,
  },
  // {
  //   text: "Schedule",
  //   link: "/schedule/" + tag.companyEventId + "/2",
  //   func: 2,
  //   icon: faCalendarAlt,
  // },

  {
    text: 'Send Meeting Request',
    func: 5,
    icon: faUserPlus,
  },
  {
    text: 'Export Summit Activity Log',
    icon: faFileExport,
    func: 9,
  },
  {
    text: 'Send Message',
    icon: faEnvelope,
    func: 7,
  },

  {
    text: 'Sign In As ',
    icon: faSignInAlt,
    submenu: true,
  },
]

export const summitFundAttendeesMenu = (tag, isVirtual) => {
  const menu = [
    {
      text: 'Details',
      link: `/schedule/fund/${tag.eventCompanyBoothFundId}`,

      func: 2,
      icon: faInfoCircle,
    },

    {
      text: 'Send Meeting Request',
      func: 5,
      icon: faUserPlus,
    },
    {
      text: 'Export Summit Activity Log',
      icon: faFileExport,
      func: 9,
    },
    {
      text: 'Send Message',
      icon: faEnvelope,
      func: 7,
    },

    {
      text: 'Sign In As ',
      icon: faSignInAlt,
      submenu: true,
    },
    ,
  ]
  if (!isVirtual) {
    menu.push({
      text: 'Booth Management',
      link: `/booth/management/${tag.fundEventId}`,
      icon: faCodeBranch,
    })

    menu.push({
      text: 'Booth Management V2',
      link: `/booth/management/v2/${tag.fundEventId}`,
      icon: faCodeBranch,
    })
  }

  return menu
}

export const pastSummitFundMenus = (tag) => [
  {
    text: 'Details',
    link: `/schedule/${tag.companyEventId}`,
    func: 2,
    icon: faInfoCircle,
  },
  {
    text: 'Schedule',
    link: `/schedule/${tag.companyEventId}/2`,
    func: 2,
    icon: faCalendarAlt,
  },

  {
    text: 'Export Summit Activity Log',
    icon: faFileExport,
    func: 9,
  },
  {
    text: 'Send Message',
    icon: faEnvelope,
    func: 7,
  },

  {
    text: 'Sign In As ',
    icon: faSignInAlt,
    submenu: true,
  },
]

export const userListMenus = (tag) => [
  {
    text: 'User Profile',
    link: `/users/${tag.contactId}`,
    func: 1,
    icon: faInfoCircle,
  },
  {
    text: `Sign In As ${tag.firstName} ${tag.lastName}`,
    icon: faSignInAlt,
    func: `signisas_${tag.contactId}`,
  },
  {
    text: 'Change Visibility',
    icon: faEyeSlash,
    func: 8,
  },
  {
    text: 'Delete User',
    link: '#',
    func: 6,
    icon: faTrashAlt,
  },
]

export const summitAllocatorCompaniesMenus = (tag) => [
  {
    text: 'Details',
    link: `/summits/${tag.eventId}/attendees/allocators/${tag.company.name}`,
    icon: faInfoCircle,
  },
  // {
  //   text: "Schedule",
  //   link: "/schedule/" + tag.companyEventId + "/2",
  //   func: 2,
  //   icon: faCalendarAlt,
  // },
  {
    text: 'Send Meeting Request',
    icon: faUserPlus,
    func: 5,
  },

  {
    text: 'Export Summit Activity Log',
    icon: faFileExport,
    func: 9,
  },
  {
    text: 'Sign In As ',
    icon: faSignInAlt,
    submenu: true,
  },
]

export const summitAllocatorAttendeesMenu = (tag, isVirtual) => {
  const menu = [
    {
      text: 'Details',
      link: `/schedule/allocator/${tag.eventParticipantId}`,
      icon: faInfoCircle,
    },

    {
      text: 'Send Meeting Request',
      icon: faUserPlus,
      func: 5,
    },

    {
      text: 'Export Summit Activity Log',
      icon: faFileExport,
      func: 9,
    },
    {
      text: 'Sign In As ',
      icon: faSignInAlt,
      submenu: true,
    },
  ]
  if (!isVirtual)
    menu.push({
      text: 'Manage Schedules',
      link: `/split/${tag.eventParticipantId}`,
      icon: faCodeBranch,
    })
  return menu
}

export const summitCFNAttendeesMenu = (tag) => {
  const menu = [
    {
      text: 'Details',
      link: `/schedule/family-member/${tag.eventParticipantId}`,
      icon: faInfoCircle,
    },
    {
      text: 'Sign In As ',
      icon: faSignInAlt,
      submenu: true,
    },
  ]

  return menu
}
export const onlineAllocatorCompaniesMenus = (tag) => [
  /*
    {
      text: "Details",
      link: `/schedule/${tag.companyEventId}`,
      icon: faInfoCircle,
    },
    {
      text: "Schedule",
      link: "/schedule/" + tag.companyEventId + "/2",
      func: 2,
      icon: faCalendarAlt,
    },
    */
  {
    text: 'Send Meeting Request',
    icon: faUserPlus,
    func: 5,
  },
  /*
    {
      text: "Export Summit Activity Log",
      icon: faFileExport,
      func: 9,
    },
    {
      text: "Sign In As ",
      icon: faSignInAlt,
      submenu: true,
    },
    {
      text: "Split Schedules",
      link: `/split/${tag.companyEventId}`,
      icon: faCodeBranch,
    },
    */
]

export const onlineSPCompaniesMenus = (tag) => [
  {
    text: 'Send Meeting Request',
    icon: faUserPlus,
    func: 5,
  },
]

export const onlineCFNCompaniesMenus = (tag) => [
  {
    text: 'Send Meeting Request',
    icon: faUserPlus,
    func: 5,
  },
]

export const pastSummitAllocatorCompaniesMenus = (tag) => [
  {
    text: 'Details',
    link: `/schedule/${tag.companyEventId}`,
    icon: faInfoCircle,
  },
  {
    text: 'Schedule',
    link: `/schedule/${tag.companyEventId}/2`,
    func: 2,
    icon: faCalendarAlt,
  },

  {
    text: 'Export Summit Activity Log',
    icon: faFileExport,
    func: 9,
  },
  {
    text: 'Sign In As ',
    icon: faSignInAlt,
    submenu: true,
  },
  {
    text: 'Split Schedules',
    link: `/split/${tag.companyEventId}`,
    icon: faCodeBranch,
  },
]

export const summitServiceProviderCompaniesMenus = (tag) => [
  {
    text: 'Details',
    link: `/schedule/sp/${tag.eventCompanyBoothId}`,
    icon: faInfoCircle,
  },
  {
    text: 'Send Meeting Request',
    icon: faUserPlus,
    func: 5,
  },
  {
    text: 'Send Message',
    icon: faEnvelope,
    func: 7,
  },
  {
    text: 'Export Summit Activity Log',
    icon: faFileExport,
    func: 9,
  },
  {
    text: 'Sign In As ',
    icon: faSignInAlt,
    submenu: true,
  },
  // {
  //   text: "Split Schedules",
  //   link: `/split/${tag.companyEventId}`,
  //   icon: faCodeBranch,
  // },
  {
    text: 'Booth Management V2',
    link: `/sp/booth/management/v2/${tag.eventCompanyId}`,
    icon: faCodeBranch,
  },
]

export const pastSummitServiceProviderCompaniesMenus = (tag) => [
  {
    text: 'Details',
    link: `/schedule/${tag.companyEventId}`,
    icon: faInfoCircle,
  },
  {
    text: 'Schedule',
    link: `/schedule/${tag.companyEventId}/2`,
    func: 2,
    icon: faCalendarAlt,
  },

  {
    text: 'Send Message',
    icon: faEnvelope,
    func: 7,
  },
  {
    text: 'Export Summit Activity Log',
    icon: faFileExport,
    func: 9,
  },
  {
    text: 'Sign In As ',
    icon: faSignInAlt,
    submenu: true,
  },
  {
    text: 'Split Schedules',
    link: `/split/${tag.companyEventId}`,
    icon: faCodeBranch,
  },
]

export const onlineFundMenus = (tag) => [
  {
    text: 'Send Meeting Request',
    func: 5,
    icon: faUserPlus,
  },
]

export const adminUserMenus = (tag) => [
  {
    text: 'Edit User',
    func: 15,
    icon: faEdit,
  },
  {
    text: 'Delete User',
    func: 16,
    icon: faTrashAlt,
  },
]

export const announcementListMenus = (tag) => [
  {
    text: 'Edit',
    func: 17,
    icon: faEdit,
  },
]

export const dealsListMenus = (tag) => [
  {
    text: 'Edit',
    func: 18,
    icon: faEdit,
  },
]

export const contentEntryMenus = (tag) => {
  const menus = [
    {
      text: 'Edit',
      func: 19,
      icon: faEdit,
      visible: true,
    },
    {
      text: 'Set as Featured',
      func: 20,
      icon: faStar,
      visible: !tag.isFeatured,
    },
    {
      text: tag.isPublished ? 'Unpublish' : 'Publish',
      func: 21,
      icon: tag.isPublished ? faTrashAlt : faCheck,
      visible: true,
    },
  ]

  return filter(menus, 'visible')
}
