import { useEffect, useState } from 'react'
import { Button } from '@context365/button'
import { Add } from '@context365/icons'
import { Col, Row, Tabs } from 'antd'
import isNil from 'lodash/isNil'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import {
  getCompanyAllocatorsList,
  getCompanyCFNList,
  getCompanyFundsList,
  getCompanyMediaList,
  getCompanyServiceProvidersList,
} from '../../actions/company'
import AdditionButton from '../../components/AdditionButton/AdditionButton'
import CenteredPrimaryRadioButtons from '../../components/CenteredPrimaryRadioButtons'
import ContextTable from '../../components/ContextTable'
import http from '../../utils/api'
import {
  getAllocatorDefaultColumns,
  getCFNDefaultColumns,
  getFundCompanyColumns,
  getMediaDefaultColumns,
  getServiceProviderDefaultColumns,
} from '../../utils/columns'
import '../../styles/antd-custom.less'

const Companies = () => {
  const { TabPane } = Tabs

  const [allocatorData, setAllocatorData] = useState({})
  const [fundData, setFundData] = useState({})
  const [serviceProviderData, setServiceProviderData] = useState({})
  const [MediaData, setMediaData] = useState({})
  const [cfnData, setCfnData] = useState({})

  const [cnt, setCnt] = useState(0)
  const [refreshData, setRefreshData] = useState(false)

  const refresh = () => {
    setRefreshData(!refreshData)
  }

  const allocatorColumns = getAllocatorDefaultColumns(refresh)

  const fundColumns = getFundCompanyColumns(refresh)

  const serviceProviderColumns = getServiceProviderDefaultColumns(refresh)
  const mediaColumns = getMediaDefaultColumns(refresh)
  const cfnColumns = getCFNDefaultColumns(refresh)

  const { url } = useRouteMatch()
  const { push } = useHistory()

  const radioHandler = (e) => {
    push(e.target.value)
  }

  const tabName = (name, data) => {
    let value
    if (!isNil(data) && data.total !== undefined) {
      value = `(${data.total})`
      //if (cnt === 5)
      name += value
    }
    return name
  }

  const buttons = [
    {
      value: '/',
      text: tabName('ALLOCATORS ', allocatorData),
    },
    {
      value: '/managers',
      text: tabName('MANAGERS ', fundData),
    },
    {
      value: '/service-providers',
      text: tabName('SERVICE PROVIDERS ', serviceProviderData),
    },

    {
      value: '/cfn',
      text: tabName('CFN ', cfnData),
    },
    {
      value: '/media',
      text: tabName('MEDIA ', MediaData),
    },
  ]

  useEffect(() => {
    http
      .post('/companies', {
        pagination: { pageSize: 1 },
        sorter: {},
        searchTerm: {},
        filters: {},
      })
      .then((res) => {
        const count = cnt
        setFundData(res.data)
        setCnt(count + 1)
      })

    http
      .post('/allocators', {
        pagination: { pageSize: 1 },
        sorter: {},
        searchTerm: {},
        filters: {},
      })
      .then((res) => {
        const count = cnt
        setAllocatorData(res.data)
        setCnt(count + 1)
      })

    http
      .post('/serviceproviders', {
        pagination: { pageSize: 1 },
        sorter: {},
        searchTerm: {},
        filters: {},
      })
      .then((res) => {
        const count = cnt
        setServiceProviderData(res.data)
        setCnt(count + 1)
      })

    http
      .post('/cfn', {
        pagination: { pageSize: 1 },
        sorter: {},
        searchTerm: {},
        filters: {},
      })
      .then((res) => {
        const count = cnt
        setCfnData(res.data)
        setCnt(count + 1)
      })

    http
      .post('/media', {
        pagination: { pageSize: 1 },
        sorter: {},
        searchTerm: {},
        filters: {},
      })
      .then((res) => {
        const count = cnt
        setMediaData(res.data)
        setCnt(count + 1)
      })
  }, [refreshData])

  return (
    <div style={{ marginTop: '20px' }}>
      <Row>
        <Col span={12}>
          <h1 className="cc-section-header-title">{'Companies'}</h1>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Button
            as={Link}
            to={`/company/new`}
            variant="filled"
            iconLeft={<Add />}
          >
            Create Company
          </Button>
        </Col>
      </Row>

      <CenteredPrimaryRadioButtons
        selectedKey={url}
        buttons={buttons}
        OnClickFunction={radioHandler}
      />
      <Tabs animated={false} tabBarStyle={{ display: 'none' }} activeKey={url}>
        <TabPane tab={tabName('ALLOCATORS', allocatorData)} key="/">
          <ContextTable
            tableTitle="Allocators"
            columns={allocatorColumns}
            dataMethod={getCompanyAllocatorsList}
            scroll={{ x: 1900 }}
            refresh={refreshData}
            refreshData={refreshData}
          />
        </TabPane>
        <TabPane tab={tabName('MANAGERS', fundData)} key="/managers">
          {' '}
          <ContextTable
            tableTitle="Managers"
            columns={fundColumns}
            dataMethod={getCompanyFundsList}
            scroll={{ x: 1900 }}
            refresh={refreshData}
            refreshData={refreshData}
          />
        </TabPane>
        <TabPane
          tab={tabName('SERVICE PROVIDERS', serviceProviderData)}
          key="/service-providers"
        >
          <ContextTable
            tableTitle="Service Providers"
            columns={serviceProviderColumns}
            dataMethod={getCompanyServiceProvidersList}
            scroll={{ x: 1900 }}
            refresh={refreshData}
            refreshData={refreshData}
          />
        </TabPane>
        <TabPane tab={tabName('CFN', cfnData)} key="/cfn">
          <ContextTable
            tableTitle="CFN"
            columns={cfnColumns}
            dataMethod={getCompanyCFNList}
            scroll={{ x: 1900 }}
            refresh={refreshData}
            refreshData={refreshData}
          />
        </TabPane>
        <TabPane tab={tabName('MEDIA', MediaData)} key="/media">
          <ContextTable
            tableTitle="Media"
            columns={mediaColumns}
            dataMethod={getCompanyMediaList}
            scroll={{ x: 1900 }}
            refresh={refreshData}
            refreshData={refreshData}
          />
        </TabPane>
      </Tabs>
    </div>
  )
}

Companies.propTypes = {
  //width: PropTypes.number.isRequired,
}

export default Companies
