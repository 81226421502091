import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Checkbox, CheckboxGroup, Input, Select } from '@context365/forms'
import { Save } from '@context365/icons'
import { Select as AntSelect, message } from 'antd'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { Link, useHistory } from 'react-router-dom'
import { getCompanyContacts } from '../../actions/company'
import {
  createEventRegistration,
  getEventPackages,
  getEventRegistration,
} from '../../actions/events'
import http from '../../utils/api'

const { OptGroup } = AntSelect

const EventRegistrationForm = ({ eventId }) => {
  const [eventCompany, setEventCompany] = useState({})
  const [companies, setCompanies] = useState([])
  const [companyContacts, setCompanyContacts] = useState([])
  const [eventPackages, setEventpackages] = useState([])

  const history = useHistory()

  const [touched, setTouched] = useState(false)

  useEffect(() => {
    if (!isNil(eventCompany.companyId)) {
      getEventRegistration(eventId, eventCompany.companyId).then((res) =>
        setEventCompany(res.data)
      )
      getCompanyContacts(eventCompany.companyId).then((res) =>
        setCompanyContacts(res.data)
      )
    }
  }, [eventCompany.companyId])

  useEffect(() => {
    getEventPackages(eventId).then((res) => setEventpackages(res.data))
  }, [eventId])

  const handleChange = useCallback(
    (key, value) => {
      setEventCompany({ ...eventCompany, [key]: value })
    },
    [eventCompany]
  )

  const handleSave = () => {
    if (
      isNil(eventCompany.eventPackageId) ||
      isNil(eventCompany.isActive) ||
      eventCompany.eventPackageId === 0
    ) {
      setTouched(true)
    } else {
      createEventRegistration(eventId, eventCompany)
        .then(() => {
          message.success('Company registered successfully')
          history.push(`/summits/${eventId}/attendees`)
        })
        .catch(() =>
          message.error('An error occurred while registering company')
        )
    }
  }

  const handleFilterOption = (e) => {
    if (e.length > 2)
      http.post(`/companies/search/online/-1?keyword=${e}`).then((res) => {
        setCompanies(res.data.response)
      })
  }

  const handleSelectChange = (e) => {
    let selectedCompany = companies.funds.filter(
      (obj) => obj.company.companyId === e
    )
    if (selectedCompany.length === 0) {
      selectedCompany = companies.allocators.filter(
        (obj) => obj.company.companyId === e
      )
      if (selectedCompany.length === 0) {
        selectedCompany = companies.serviceProviders.filter(
          (obj) => obj.company.companyId === e
        )
      }
    }

    handleChange('companyId', selectedCompany[0].company.companyId)
  }

  return (
    <>
      <div className="bg-white p-4">
        <div className="type-header-md mb-4">Event Registration</div>
        <div className="grid grid-cols-4 gap-4">
          <div>
            <label>Company</label>
            <AntSelect
              showSearch
              size="large"
              style={{ width: '100%', height: '48px' }}
              placeholder="Search by Company Name"
              showArrow={false}
              optionFilterProp="children"
              onChange={handleSelectChange}
              onSearch={handleFilterOption}
              filterOption={false}
              value={eventCompany.companyId}
            >
              <OptGroup label="Allocator Companies">
                {companies.allocators &&
                  companies.allocators.map((row) => {
                    if (row.company.category.toLowerCase() === 'allocator') {
                      return (
                        <AntSelect.Option value={row.company.companyId}>
                          {row.company.name}
                        </AntSelect.Option>
                      )
                    }
                  })}
              </OptGroup>
              <OptGroup label="Fund Manager Companies">
                {companies &&
                  companies.funds &&
                  companies.funds.map((row) => {
                    if (row.company.category.toLowerCase() === 'manager') {
                      return (
                        <AntSelect.Option
                          value={row.company.companyId}
                          key={row.company.companyId}
                        >
                          {row.company.name}
                        </AntSelect.Option>
                      )
                    }
                  })}
              </OptGroup>
              <OptGroup label="Service Providers">
                {companies &&
                  companies.serviceProviders &&
                  companies.serviceProviders.map((row) => {
                    if (
                      row.company.category.toLowerCase() === 'service provider'
                    ) {
                      return (
                        <AntSelect.Option
                          value={row.company.companyId}
                          key={row.company.companyId}
                        >
                          {row.company.name}
                        </AntSelect.Option>
                      )
                    }
                  })}
              </OptGroup>
            </AntSelect>
          </div>
          {!isNil(eventCompany.companyId) && (
            <>
              <Select
                className="col-start-1"
                label="Status"
                value={eventCompany.isActive}
                required
                onChange={(e) => handleChange('isActive', e)}
                errorMessage={
                  touched && isNil(eventCompany.isActive) ? 'Required' : null
                }
              >
                <Select.Option value={true} key="confirmed">
                  Confirmed
                </Select.Option>
                <Select.Option value={false} key="cancelled">
                  Cancelled
                </Select.Option>
              </Select>
              <Select
                label="Package"
                required
                onChange={(e) => handleChange('eventPackageId', e)}
                value={eventCompany.eventPackageId}
                errorMessage={
                  touched &&
                  (isNil(eventCompany.eventPackageId) ||
                    eventCompany.eventPackageId === 0)
                    ? 'Required'
                    : null
                }
              >
                {map(eventPackages, (p) => (
                  <Select.Option value={p.id} key={p.id}>
                    {p.name}
                  </Select.Option>
                ))}
              </Select>
              <Input
                label="Booth Count"
                type="number"
                value={eventCompany.boothCount}
                onChange={(e) => handleChange('boothCount', e.target.value)}
              />
              <Input
                label="Meeting Credits"
                type="number"
                value={eventCompany.meetingCredits}
                onChange={(e) => handleChange('meetingCredits', e.target.value)}
              />

              <div className="type-header-md mb-4">Contacts Attending</div>
              <div className="col-start-1 col-span-2">
                <CheckboxGroup
                  selected={eventCompany.contactIds}
                  onChange={(e) => handleChange('contactIds', e)}
                >
                  <div className="grid grid-cols-2 gap-1">
                    {map(companyContacts, (c) => (
                      <Checkbox value={c.contactId}>{c.contactName}</Checkbox>
                    ))}
                  </div>
                </CheckboxGroup>
              </div>
            </>
          )}
        </div>
        <div className="mt-8 flex justify-between">
          <Button
            as={Link}
            to={`/summits/${eventId}/attendees/allocators`}
            variant="filled"
            status="error"
          >
            Cancel
          </Button>
          <Button iconLeft={<Save />} onClick={handleSave} variant="filled">
            Save
          </Button>
        </div>
      </div>
    </>
  )
}

EventRegistrationForm.propTypes = {
  eventId: PropTypes.number.isRequired,
}

export default EventRegistrationForm
