import EventRegistrationForm from '../../components/EventRegistrationForm/EventRegistrationForm'

const EventRegistration = ({
  match: {
    params: { eventId },
  },
}) => {
  return <EventRegistrationForm eventId={eventId} />
}

export default EventRegistration
