import {
  COMPANY_FETCHING,
  COMPANY_FETCH_FAILURE,
  COMPANY_FETCH_SUCCESS,
} from '../constants/types/company'
import http from '../utils/api'

export const getCompany = () => ({
  type: COMPANY_FETCHING,
})

export const getCompanySuccess = (company) => ({
  type: COMPANY_FETCH_SUCCESS,
  payload: company,
})

export const getCompanyFailure = (error) => ({
  type: COMPANY_FETCH_FAILURE,
  payload: error,
})

export const shouldFetchCompany = (state) => !state.company.isFetching

export const fetchCompany = (companyId) => (dispatch) => {
  dispatch(getCompany())

  http
    .get(`/companies/${companyId}`)
    .then((response) => dispatch(getCompanySuccess(response.data.result)))
    .catch((error) => dispatch(getCompanyFailure(error)))
}

export const fetchCompanyIfNeeded = (companyId) => (dispatch, getState) => {
  if (shouldFetchCompany(getState())) {
    dispatch(fetchCompany(companyId))
  }
}

export const getCompaniesContacts = (firstCompanyId, secondCompanyId) =>
  http.get(`/companies/${firstCompanyId},${secondCompanyId}/contacts`)

export const makeAdministrator = (contactId) =>
  http.put(`/colleagues/${contactId}/admin`)

export const revokeAdministratorRights = (contactId) =>
  http.put(`/colleagues/${contactId}/revoke`)

export const deleteColleague = (contactId) =>
  http.delete(`/colleagues/${contactId}`)

export const getCompanyAllocatorsList = (
  pagination,
  filters,
  sorter,
  searchTerm
) =>
  http.post('/allocators', {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getCompanyFundsList = (pagination, filters, sorter, searchTerm) =>
  http.post('/companies', {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getVettedAndUnvettedManagers = (searchTerm) =>
  http.get('/companies/managers/all', {
    params: {
      searchTerm,
    },
  })

export const getAllocatorCompanyEventsList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId,
  eventParticipantId,
  fundEventId,
  eventCompanyId,
  companyContactId,
  fundId,
  companyId
) =>
  http.post(`allocators/${companyId}/companyEvents`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getFundCompanyEventsList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId,
  eventParticipantId,
  fundEventId,
  eventCompanyId,
  companyContactId,
  fundId,
  companyId
) =>
  http.post(`funds/${companyId}/companyEvents`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getServiceProviderCompanyEventsList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId,
  eventParticipantId,
  fundEventId,
  eventCompanyId,
  companyContactId,
  fundId,
  companyId
) =>
  http.post(`serviceproviders/${companyId}/companyEvents`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getMediaCompanyEventsList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId,
  eventParticipantId,
  fundEventId,
  eventCompanyId,
  companyContactId,
  fundId,
  companyId
) =>
  http.post(`media/${companyId}/companyEvents`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getCompanyServiceProvidersList = (
  pagination,
  filters,
  sorter,
  searchTerm
) =>
  http.post('/serviceproviders', {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getCompanyMediaList = (pagination, filters, sorter, searchTerm) =>
  http.post('/media', {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getCompanyCFNList = (pagination, filters, sorter, searchTerm) =>
  http.post('/cfn', {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getCompanyCategories = () => http.get(`/companies/categories`)

export const getContactCompanies = (contactId) =>
  http.get(`/contacts/${contactId}/companies`)

export const getCompanyFunds = (companyId) =>
  http.get(`/companies/${companyId}/funds`)

export const getTierOptions = () => http.get(`companies/tierOptions`)

export const checkForDupeCompanies = (name) =>
  http.get(`companies/dupes`, {
    params: {
      name,
    },
  })

export const createCompany = (company) => http.post(`companies/create`, company)

export const getCompanyContacts = (companyId) =>
  http.get(`/companies/${companyId}/contacts`)
