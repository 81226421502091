import { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import BoothManagement2 from '../components/BoothManagement-v2/BoothManagement2'
import BoothManagement from '../components/BoothManagement/BoothManagement'
import BoothManagementSP from '../components/BoothManagementSP/BoothManagementSP'
import Charts from '../components/Charts/Charts'
import CompanyEvent from '../components/CompanyEvent/CompanyEvent'
import ContactCompanies from '../components/ContactCompanies/ContactCompanies'
import ContactForm from '../components/ContactForm/ContactForm'
import ContentEntries from '../components/ContentEntries/ContentEntries'
import InvestorDetailsForm from '../components/InvestorDetailsForm/InvestorDetailsForm'
import InvestorStatusForm from '../components/InvestorStatusForm/InvestorStatusForm'
import LeadList from '../components/LeadList/LeadList'
import noTimeSplit from '../components/noTimeSplit/noTimeSplit'
import OnlineDetailsContainer from '../components/OnlineDetailsContainer/OnlineDetailsContainer'
import Schedule from '../components/Schedule/Schedule'
import EditManagerDetails from '../views/editManagerDetails/EditManagerDetails'
import AdminContainer from './admin/AdminContainer'
import AddAnnouncement from './announcements/AddAnnouncement'
import AnnouncementsContainer from './announcements/AnnouncementsContainer'
import AppLayout from './AppLayout'
import Companies from './Companies/Companies'
import CompanyDetailsContainer from './CompanyDetailsContainer'
import ContentEntryWizardContainer from './ContentEntryWizardContainer'
import AddDeal from './deals/AddDeal'
import DealsContainer from './deals/DealsContainer'
import EventRegistration from './EventRegistration/EventRegistration'
import Login from './login/login'
import MandatesContainer from './mandates/MandatesContainer'
import MandateWizardForm from './MandateWizardForm'
import NewCompany from './NewCompany/NewCompany'
import NewContact from './NewContact/NewContact'
import NewEvent from './NewEvent/NewEvent'
import OnlineContainer from './online/OnlineContainer'
import ProtectedRoute from './ProtectedRoute'
import LogsContainer from './SalesforceLogs/LogsContainer'
import SummitDetailsContainer from './SummitDetailsContainer'
import Summits from './Summits/Summits'
import UserDetailsContainer from './UserDetailsContainer'
import Users from './Users/Users'

const App = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [collapsed, handleCollapse] = useState(window.innerWidth < 1025)

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        setWidth(window.innerWidth)
        if (window.innerWidth < 1025) {
          handleCollapse(true)
        } else {
          handleCollapse(false)
        }
      }, 100)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <div>
      <Switch>
        <Route
          path="/login"
          render={(props) => (
            <Login
              {...props}
              width={width}
              collapsed={collapsed}
              handleCollapse={handleCollapse}
            />
          )}
        />
        <ProtectedRoute exact path="/" component={Companies} />{' '}
        <ProtectedRoute exact path="/managers" component={Companies} />
        <ProtectedRoute exact path="/service-providers" component={Companies} />
        <ProtectedRoute exact path="/cfn" component={Companies} />
        <ProtectedRoute exact path="/media" component={Companies} />
        <ProtectedRoute exact path="/summits" component={Summits} />
        <ProtectedRoute
          exact
          path="/split/:companyEventId"
          component={noTimeSplit}
        />
        <ProtectedRoute
          exact
          path="/booth/management/:fundEventId"
          component={BoothManagement}
        />
        <ProtectedRoute
          exact
          path="/booth/management/v2/:fundEventId"
          component={BoothManagement2}
        />
        <ProtectedRoute
          exact
          path="/sp/booth/management/v2/:eventCompanyId"
          component={BoothManagementSP}
        />
        <ProtectedRoute exact path="/users" component={Users} />
        <ProtectedRoute
          path="/schedule/:type/:recordId/:tab?"
          component={CompanyEvent}
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
        />
        <ProtectedRoute
          path="/summits/:eventId/:main?/:tab?/:searchTerm?"
          component={SummitDetailsContainer}
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
        />
        <ProtectedRoute
          path="/companies/:category/:companyId/:tab?"
          component={CompanyDetailsContainer}
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
        />
        <ProtectedRoute
          path="/users/:contactId/:tab?"
          component={UserDetailsContainer}
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
        />
        <ProtectedRoute
          path="/contactdetails/:contactId"
          component={ContactForm}
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
        />
        <ProtectedRoute
          path="/investorstatus/:companyId/:contactId"
          component={InvestorStatusForm}
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
        />
        <ProtectedRoute
          path="/investordetails/:companyId/:contactId"
          component={InvestorDetailsForm}
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
        />
        <ProtectedRoute
          path="/contactcompanies/:contactId"
          component={ContactCompanies}
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
        />
        <ProtectedRoute
          exact
          path="/summits/:eventId/schedule/:companyEventId"
          component={Schedule}
        />
        <ProtectedRoute exact path="/company/new" component={NewCompany} />
        <ProtectedRoute exact path="/contact/new" component={NewContact} />
        <ProtectedRoute
          exact
          path="/event/new"
          component={NewEvent}
          admin={true}
        />
        <ProtectedRoute
          exact
          path="/event/:eventId/register"
          component={EventRegistration}
        />
        <ProtectedRoute
          path="/leads"
          component={LeadList}
          width={width}
          handleCollapse={handleCollapse}
          collapsed={collapsed}
        />
        <ProtectedRoute
          path="/content"
          marketing={true}
          component={ContentEntries}
          width={width}
          handleCollapse={handleCollapse}
          collapsed={collapsed}
        />
        <ProtectedRoute
          path="/analytics"
          component={Charts}
          width={width}
          handleCollapse={handleCollapse}
          collapsed={collapsed}
        />
        <ProtectedRoute
          exact
          path="/editManagerDetails/:companyId/:contactId"
          component={EditManagerDetails}
        />
        <ProtectedRoute
          path="/online/:type/:recordId/:tab?"
          component={OnlineDetailsContainer}
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
        />
        <ProtectedRoute
          path="/online/:type?"
          component={OnlineContainer}
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
        />
        <ProtectedRoute
          path="/admin"
          admin={true}
          component={AdminContainer}
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
        />
        <ProtectedRoute
          exact
          marketing={true}
          width={width}
          path="/announcements"
          component={AnnouncementsContainer}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
        />
        <ProtectedRoute
          exact
          marketing={true}
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
          path="/announcements/new"
          component={AddAnnouncement}
        />
        <ProtectedRoute
          exact
          marketing={true}
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
          path="/announcements/edit/:id"
          component={AddAnnouncement}
        />
        <ProtectedRoute
          exact
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
          path="/deals"
          component={DealsContainer}
        />
        <ProtectedRoute
          exact
          //marketing={true}
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
          path="/deals/new"
          component={AddDeal}
        />
        <ProtectedRoute
          exact
          //marketing={true}
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
          path="/deals/:dealId/"
          component={AddDeal}
        />
        <ProtectedRoute
          exact
          marketing={true}
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
          path="/contententries/new"
          component={ContentEntryWizardContainer}
        />
        <ProtectedRoute
          exact
          marketing={true}
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
          path="/contententries/edit/:contentEntryId"
          component={ContentEntryWizardContainer}
        />
        <ProtectedRoute
          exact
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
          path="/logs"
          component={LogsContainer}
        />
        <ProtectedRoute
          exact
          width={width}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
          path="/mandates"
          component={MandatesContainer}
        />
        <ProtectedRoute
          path="/create-mandate"
          component={MandateWizardForm}
          width={width}
          handleCollapse={handleCollapse}
          collapsed={collapsed}
        />
        <ProtectedRoute
          path="/edit-mandate/:mandateId"
          component={MandateWizardForm}
          width={width}
          handleCollapse={handleCollapse}
          collapsed={collapsed}
        />
      </Switch>
    </div>
  )
}

export default App
