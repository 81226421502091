import http from '../utils/api'

export const deleteContact = (contactId) =>
  http.delete(`/contacts/${contactId}`)

export const getUserList = (pagination, filters, sorter, searchTerm) =>
  http.post('/contacts', {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getAllUsers = (keyword) =>
  http.get(`contacts/all?keyword=${keyword}`)

export const createContact = (contact) =>
  http.post('/contacts/create', {
    ...contact,
  })
