import { useEffect, useState } from 'react'
import { Button } from '@context365/button'
import { Add } from '@context365/icons'
import { Col, Divider, Empty, Row, Spin } from 'antd'
import { isEmpty, isNil, map } from 'lodash'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getPastEventsList, getUpcomingEvents } from '../../actions/events'
import AvailableEvent from '../../components/AvailableEvent'
import ContextTable from '../../components/ContextTable'
import EmptyState from '../../components/EmptyState'
import SectionHeader from '../../components/SectionHeader'
import { getAdminStatus } from '../../selectors/auth'
import { getPastSummits } from '../../utils/columns'

const Summits = () => {
  const [availableEvents, setAvailableEvents] = useState(null)

  const defaultSort = {
    order: 'descend',
    field: 'startDate',
    columnKey: 'startDate',
  }

  useEffect(() => {
    getUpcomingEvents().then((res) => {
      setAvailableEvents(res.data)
    })
  }, [])

  const isAdmin = useSelector(getAdminStatus)
  const columns = getPastSummits
  const availableEventsSection = isEmpty(availableEvents) ? (
    <EmptyState message="No Available Summits for the moment" />
  ) : (
    map(availableEvents, (e) => <AvailableEvent key={e.eventId} event={e} />)
  )
  if (isNil(availableEvents)) {
    return (
      <Spin spinning="true">
        <Empty />
      </Spin>
    )
  }
  return (
    <>
      <Row>
        <Col span={24}>
          <div className="flex justify-between">
            <SectionHeader title="Upcoming Summits" />
            {isAdmin && (
              <Button
                as={Link}
                to={`/event/new`}
                variant="filled"
                iconLeft={<Add />}
              >
                Create Event
              </Button>
            )}
          </div>
          {availableEventsSection}
        </Col>
      </Row>
      <Divider
        type="horizontal"
        style={{ backgroundColor: 'transparent', margin: '10px' }}
      />
      <Row>
        <Col span={24}>
          <SectionHeader title="Past Summits" />
          <ContextTable
            tableTitle="Past Summits"
            columns={columns}
            //sorter={this.defaultSort}
            dataMethod={getPastEventsList}
          />
        </Col>
      </Row>
    </>
  )
}

export default Summits
