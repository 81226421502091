import { useCallback, useEffect, useState } from 'react'
import { Button } from '@context365/button'
import { Input, Select } from '@context365/forms'
import { Save } from '@context365/icons'
import { Select as AntSelect, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { Link, useHistory } from 'react-router-dom'
import { getCountries } from '../../actions/fundList'
import { createContact } from '../../actions/users'
import http from '../../utils/api'

const { OptGroup } = AntSelect

const NewContactForm = () => {
  const [contact, setContact] = useState({})

  const history = useHistory()

  const [countries, setCountries] = useState([])
  const [companies, setCompanies] = useState([])
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    getCountries().then((res) => setCountries(res.data))
  }, [])

  const handleChange = useCallback(
    (key, value) => {
      setContact({ ...contact, [key]: value })
    },
    [contact]
  )

  const handleSave = () => {
    if (
      isEmpty(contact.firstName) ||
      isNil(contact.email) ||
      isNil(contact.lastName)
    ) {
      setTouched(true)
    } else if (isEmpty(contact.companyIds)) {
      message.error('Please select a company')
    } else {
      createContact(contact)
        .then(() => {
          message.success('Contact created successfully')
          history.push('/users')
        })
        .catch((err) => {
          if (err.response.data.statusCode) {
            message.error('A contact with this email already exists')
          } else {
            message.error('An error occurred while creating this contact')
          }
        })
    }
  }

  const handleFilterOption = (e) => {
    if (e.length > 2)
      http.post(`/companies/search/online/-1?keyword=${e}`).then((res) => {
        setCompanies(res.data.response)
      })
  }

  const handleSelectChange = (e) => {
    let selectedCompany = companies.funds.filter(
      (obj) => obj.company.companyId === e
    )
    if (selectedCompany.length === 0) {
      selectedCompany = companies.allocators.filter(
        (obj) => obj.company.companyId === e
      )
      if (selectedCompany.length === 0) {
        selectedCompany = companies.serviceProviders.filter(
          (obj) => obj.company.companyId === e
        )
      }
    }

    handleChange('companyIds', [selectedCompany[0].company.companyId])
  }

  return (
    <>
      <div className="bg-white p-4">
        <div className="type-header-md mb-4">Create New Contact</div>
        <div className="grid grid-cols-4 gap-4">
          <Input
            className="col-span-2"
            required
            label="First Name"
            value={contact.firstName}
            errorMessage={
              touched && isEmpty(contact.firstName) ? 'Required' : null
            }
            onChange={(e) => handleChange('firstName', e.target.value)}
          />
          <Input
            className="col-span-2"
            required
            label="Last Name"
            value={contact.lastName}
            errorMessage={
              touched && isEmpty(contact.lastName) ? 'Required' : null
            }
            onChange={(e) => handleChange('lastName', e.target.value)}
          />
          <Input
            className="col-span-2"
            required
            label="Email"
            value={contact.email}
            errorMessage={touched && isEmpty(contact.email) ? 'Required' : null}
            onChange={(e) => handleChange('email', e.target.value)}
          />
          <Input
            className="col-start-1"
            label="Street Address"
            value={contact.address1}
            onChange={(e) => handleChange('address1', e.target.value)}
          />
          <Input
            label="Address 2"
            value={contact.address2}
            onChange={(e) => handleChange('address2', e.target.value)}
          />
          <Input
            label="Address 3"
            value={contact.address3}
            onChange={(e) => handleChange('address3', e.target.value)}
          />
          <Select.AutoComplete
            className="col-start-1"
            label="Country"
            placeholder="Select country"
            value={contact.country}
            onChange={(e) => handleChange('country', e)}
          >
            {({ inputValue }) =>
              inputValue.length > 0
                ? map(
                    countries.filter((a) =>
                      a.name.toLowerCase().includes(inputValue.toLowerCase())
                    ),
                    (c) => (
                      <Select.Option value={c.name}>{c.name}</Select.Option>
                    )
                  )
                : 'Begin typing to search'
            }
          </Select.AutoComplete>
          <Input
            label="City"
            value={contact.city}
            onChange={(e) => handleChange('city', e.target.value)}
          />
          <Input
            label="State/Province"
            value={contact.state}
            onChange={(e) => handleChange('state', e.target.value)}
          />
          <Input
            label="Zipcode"
            value={contact.postalCode}
            onChange={(e) => handleChange('postalCode', e.target.value)}
          />
          <div>
            <label>Company</label>
            <AntSelect
              showSearch
              size="large"
              style={{ width: '100%', height: '48px' }}
              placeholder="Search by Company Name"
              showArrow={false}
              optionFilterProp="children"
              onChange={handleSelectChange}
              onSearch={handleFilterOption}
              filterOption={false}
              value={contact.companyIds}
            >
              <OptGroup label="Allocator Companies">
                {companies.allocators &&
                  companies.allocators.map((row) => {
                    if (row.company.category.toLowerCase() === 'allocator') {
                      return (
                        <Select.Option value={row.company.companyId}>
                          {row.company.name}
                        </Select.Option>
                      )
                    }
                  })}
              </OptGroup>
              <OptGroup label="Fund Manager Companies">
                {companies &&
                  companies.funds &&
                  companies.funds.map((row) => {
                    if (row.company.category.toLowerCase() === 'manager') {
                      return (
                        <Select.Option
                          value={row.company.companyId}
                          key={row.company.companyId}
                        >
                          {row.company.name}
                        </Select.Option>
                      )
                    }
                  })}
              </OptGroup>
              <OptGroup label="Service Providers">
                {companies &&
                  companies.serviceProviders &&
                  companies.serviceProviders.map((row) => {
                    if (
                      row.company.category.toLowerCase() === 'service provider'
                    ) {
                      return (
                        <Select.Option
                          value={row.company.companyId}
                          key={row.company.companyId}
                        >
                          {row.company.name}
                        </Select.Option>
                      )
                    }
                  })}
              </OptGroup>
            </AntSelect>
          </div>
        </div>
        <div className="mt-8 flex justify-between">
          <Button as={Link} to={`/users`} variant="filled" status="error">
            Cancel
          </Button>
          <Button iconLeft={<Save />} onClick={handleSave} variant="filled">
            Save
          </Button>
        </div>
      </div>
    </>
  )
}

export default NewContactForm
