import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Add } from '@context365/icons'
import { Tabs } from 'antd'
import { filter } from 'lodash'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import CenteredPrimaryRadioButtons from '../CenteredPrimaryRadioButtons'
import SummitAttendeeAllocators from '../SummitAttendeeAllocators'
import SummitAttendeeCFNs from '../SummitAttendeeCFNs'
import SummitAttendeeFunds from '../SummitAttendeeFunds'
import SummitAttendeeManagers from '../SummitAttendeeManagers'
import SummitAttendeeServiceProviders from '../SummitAttendeeServiceProviders'
import './AttendeeList.less'

const { TabPane } = Tabs

const AttendeeList = ({
  eventId,
  pastEvent,
  virtualSummit,
  cfnEvent,
  defaultFilterValue,
}) => {
  const {
    params: { main, tab },
  } = useRouteMatch()
  const { push } = useHistory()
  const radioHandler = (e) => {
    push(`/summits/${eventId}/attendees/${e.target.value}`)
  }

  const BUTTONS = [
    cfnEvent
      ? {
          value: 'family-members',
          text: 'family members',
        }
      : null,
    {
      value: 'allocators',
      text: 'allocators',
    },
    {
      value: 'funds',
      text: 'funds',
    },
    {
      value: 'managers',
      text: 'Managers',
    },
    {
      value: 'service-providers',
      text: 'service providers',
    },

    // {
    //   value: "4",
    //   text: "media",
    // },
  ]
  const buttons = filter(BUTTONS, (x) => x !== null)
  return (
    <>
      <CenteredPrimaryRadioButtons
        selectedKey={tab ? tab : cfnEvent ? 'family-members' : 'allocators'}
        buttons={buttons}
        OnClickFunction={radioHandler}
      />
      <div className="flex justify-end p-2">
        <Button
          as={Link}
          to={`/event/${eventId}/register`}
          variant="filled"
          iconLeft={<Add />}
        >
          Add/Edit Registration
        </Button>
      </div>
      <Tabs
        animated={false}
        tabBarStyle={{ display: 'none' }}
        activeKey={tab ? tab : cfnEvent ? 'family-members' : 'allocators'}
      >
        {cfnEvent && (
          <TabPane tab="Family Members" key="family-members">
            <SummitAttendeeCFNs
              eventId={eventId}
              isPast={pastEvent}
              isVirtual={virtualSummit}
              title="Family Members"
            />
          </TabPane>
        )}
        <TabPane tab="Allocators" key="allocators">
          <SummitAttendeeAllocators
            eventId={eventId}
            isPast={pastEvent}
            isVirtual={virtualSummit}
            title="Allocators"
            defaultFilterValue={defaultFilterValue}
          />
        </TabPane>
        <TabPane tab="Funds" key="funds">
          <SummitAttendeeFunds
            eventId={eventId}
            isPast={pastEvent}
            isVirtual={virtualSummit}
            title="Funds"
            defaultFilterValue={defaultFilterValue}
          />
        </TabPane>
        <TabPane tab="Managers" key="managers">
          <SummitAttendeeManagers
            eventId={eventId}
            isPast={pastEvent}
            title="Managers"
          />
        </TabPane>
        <TabPane tab="Service Providers" key="service-providers">
          <SummitAttendeeServiceProviders
            eventId={eventId}
            isPast={pastEvent}
            isVirtual={virtualSummit}
            title="Service Providers"
          />
        </TabPane>
        {/* <TabPane tab="Media" key="4">
          <SummitAttendeeMedia
            eventId={eventId}
            isPast={pastEvent}
            title="Media"
          />
        </TabPane> */}
      </Tabs>
    </>
  )
}

AttendeeList.propTypes = {
  eventId: PropTypes.number.isRequired,
}

export default AttendeeList
