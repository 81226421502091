import axios from 'axios'
import fromPairs from 'lodash/fromPairs'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import keys from 'lodash/keys'
import map from 'lodash/map'
import min from 'lodash/min'
import values from 'lodash/values'
import moment from 'moment'
import qs from 'qs'
import {
  FETCH_EVENTS,
  FETCH_EVENTS_FAILURE,
  FETCH_EVENTS_SUCCESS,
  UPDATE_ATTENDING_EVENT,
} from '../constants/types/events'
import http from '../utils/api'

export const getEvents = () => ({
  type: FETCH_EVENTS,
})

export const getAttendingEventsSuccess = (events, eventId = 0) => {
  const attendingEventsById = fromPairs(
    map(events, (event) => [event.eventId, event])
  )

  const attendingEventsIds = keys(attendingEventsById)

  const attendingEventId =
    eventId !== 0
      ? eventId
      : get(
          min(values(attendingEventsById), (event) => moment(event.startDate)),
          'eventId',
          0
        )

  return {
    type: FETCH_EVENTS_SUCCESS,
    payload: {
      attendingEventsById,
      attendingEventsIds,
      attendingEventId,
    },
  }
}

export const getAvailableEventsSuccess = (events) => {
  const availableEventsById = fromPairs(
    map(events, (event) => [event.eventId, event])
  )

  const availableEventsIds = keys(availableEventsById)

  return {
    type: FETCH_EVENTS_SUCCESS,
    payload: {
      availableEventsById,
      availableEventsIds,
    },
  }
}

export const getPastEventsSuccess = (events) => {
  const pastEventsById = fromPairs(
    map(events, (event) => [event.eventId, event])
  )

  const pastEventsIds = keys(pastEventsById)

  return {
    type: FETCH_EVENTS_SUCCESS,
    payload: {
      pastEventsById,
      pastEventsIds,
    },
  }
}

export const getEventsFailure = (error) => ({
  type: FETCH_EVENTS_FAILURE,
  payload: error,
})

const shouldFetchEvents = (state) => !state.events.isFetching

export const fetchEvents =
  (eventId = 0) =>
  (dispatch) => {
    dispatch(getEvents())

    axios
      .all([
        http.get('/events/attending'),
        http.get('/events/available'),
        http.get('/events/past'),
      ])
      .then(
        axios.spread((attendingResponse, availableResponse, pastResponse) => {
          dispatch(
            getAttendingEventsSuccess(attendingResponse.data.result, eventId)
          )
          dispatch(getAvailableEventsSuccess(availableResponse.data.result))
          dispatch(getPastEventsSuccess(pastResponse.data.result))
        }),
        (thrownError) => {
          const err = isNil(thrownError.response)
            ? thrownError.message
            : thrownError.response.data.message
          dispatch(getEventsFailure(err))
        }
      )
  }

export const fetchEventsIfNeeded =
  (eventId = 0) =>
  (dispatch, getState) => {
    if (shouldFetchEvents(getState())) {
      dispatch(fetchEvents(eventId))
    }
  }

export const updateAttendingEvent = (eventId) => ({
  type: UPDATE_ATTENDING_EVENT,
  payload: eventId,
})

export const getEventSchedule = (eventId, companyId, eventDate, timezone) => {
  const params = { timezone }
  return http.get(
    `events/${eventId}/meetings?companyEventId=${companyId}&eventDate=${eventDate}`,
    {
      params,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }
  )
}

export const getNOEventSchedule = (eventId, companyId) =>
  http.get(`events/${eventId}/meetings?companyEventId=${companyId}`)

export const getEventDates = (eventId) =>
  http.get(`events/${eventId}/eventDates`)

export const splitSchedule = (splits) =>
  http.post('events/split-schedule', splits, {
    timeout: 30000,
  })

export const getEventAgenda = (eventId) => http.get(`events/${eventId}/agenda`)

export const getAgendaSpeakerRoles = () =>
  http.get(`events/agenda/speakerRoles`)

export const setAvailability = (
  eventId,
  companyId,
  meetingTimeId,
  availability
) => {
  if (availability === true)
    return http.put(
      `events/${eventId}/schedule/${companyId}/meetingtime/${meetingTimeId}/available`
    )
  else
    return http.put(
      `events/${eventId}/schedule/${companyId}/meetingtime/${meetingTimeId}/unavailable`
    )
}

export const getUpcomingEvent = (eventId) =>
  http.get(`events/available/${eventId}`)

export const getUpcomingEvents = () => http.get('/upcomingevents')

export const getEventAvailableTimes = (eventId, companyEventId) =>
  http.get(`events/${eventId}/${companyEventId}/availability`)

export const getNewEventAvailableTimes = (
  companyId,
  eventId,
  companyEventId,
  contactId,
  timezone
) => {
  const params = { timezone }
  return http.get(
    `events/${companyId}/${eventId}/${companyEventId}/${contactId}/availability`,
    {
      params,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }
  )
}

export const getPastEventsList = (pagination, filters, sorter, searchTerm) =>
  http.post('/pastevents', {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getEventAllocatorsList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId
) =>
  http.post(`/events/${eventId}/allocator`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getEventCFNsList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId
) =>
  http.post(`/events/${eventId}/cfns`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getEventSortFilters = (eventId, categoryId) =>
  http.get(`/events/${eventId}/${categoryId}/sortFilters`)

export const getEventFundsList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId
) =>
  http.post(`/events/${eventId}/funds`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })
export const getEventFundsListV2 = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId
) =>
  http.post(`/events/${eventId}/funds?api-version=2`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getEventAllManagersList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId
) =>
  http.post(`/events/${eventId}/managers`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getEventContentList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId
) =>
  http.post(`/events/${eventId}/content`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const addEventContent = (eventId, sponsorContentEvent) =>
  http.post(`/events/${eventId}/addcontent`, { ...sponsorContentEvent })

export const updateSponsorContentEvent = (
  sponsorContentEventId,
  sponsorContentEvent
) =>
  http.put(`/events/sponsorcontent/edit/${sponsorContentEventId}`, {
    ...sponsorContentEvent,
  })

export const getEventServiceProvidersList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId
) =>
  http.post(`/events/${eventId}/serviceproviders`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getEventServiceProvidersListV2 = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId
) =>
  http.post(`/events/${eventId}/serviceproviders?api-version=2`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getEventMediaList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId
) =>
  http.post(`/media/${eventId}/media`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getReceivedList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId,
  companyEventId
) =>
  http.post(`/meeting/summit/received?companyEventId=${companyEventId}`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getReceivedContextList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId,
  eventParticipantId,
  fundEventId,
  eventCompanyId,
  itemId,
  type,
  eventCompanyBoothFundId,
  eventCompanyBoothId
) =>
  http.post(
    `/meeting/received?eventParticipantId=${eventParticipantId}&eventCompanyBoothFundId=${eventCompanyBoothFundId}&eventCompanyBoothId=${eventCompanyBoothId}&itemId=${itemId}&type=${type}`,
    {
      pagination,
      filters,
      sorter,
      searchTerm,
    }
  )

export const getConfirmedList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId,
  companyEventId
) =>
  http.post(`/meeting/summit/confirmed?companyEventId=${companyEventId}`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getConfirmedContextList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId,
  eventParticipantId,
  fundEventId,
  eventCompanyId,
  itemId,
  type,
  eventCompanyBoothFundId,
  eventCompanyBoothId
) =>
  http.post(
    `/meeting/confirmed?eventParticipantId=${eventParticipantId}&eventCompanyBoothFundId=${eventCompanyBoothFundId}&eventCompanyBoothId=${eventCompanyBoothId}&itemId=${itemId}&type=${type}`,
    {
      pagination,
      filters,
      sorter,
      searchTerm,
    }
  )

export const getSentList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId,
  companyEventId
) =>
  http.post(`/meeting/summit/sent?companyEventId=${companyEventId}`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getSentContextList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId,
  eventParticipantId,
  fundEventId,
  eventCompanyId,

  itemId,
  type,
  eventCompanyBoothFundId,
  eventCompanyBoothId
) =>
  http.post(
    `/meeting/sent?eventParticipantId=${eventParticipantId}&eventCompanyBoothFundId=${eventCompanyBoothFundId}&eventCompanyBoothId=${eventCompanyBoothId}&itemId=${itemId}&type=${type}`,
    {
      pagination,
      filters,
      sorter,
      searchTerm,
    }
  )

export const getDeclinedList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId,
  companyEventId
) =>
  http.post(`/meeting/summit/declined?companyEventId=${companyEventId}`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getDeclinedContextList = (
  pagination,
  filters,
  sorter,
  searchTerm,
  eventId,
  eventParticipantId,
  fundEventId,
  eventCompanyId,
  itemId,
  type,
  eventCompanyBoothFundId,
  eventCompanyBoothId
) =>
  http.post(
    `/meeting/declined?eventParticipantId=${eventParticipantId}&eventCompanyBoothFundId=${eventCompanyBoothFundId}&eventCompanyBoothId=${eventCompanyBoothId}&itemId=${itemId}&type=${type}`,
    {
      pagination,
      filters,
      sorter,
      searchTerm,
    }
  )

export const getEventSuites = (
  eventId,
  pagination,
  filters,
  sorter,
  searchTerm
) =>
  http.post(`events/${eventId}/suites`, {
    pagination,
    filters,
    sorter,
    searchTerm,
  })

export const getEventKey = (eventId) => http.get(`booths/${eventId}/key`)

export const GetSeatsPublicKey = () => http.get('events/seats/publicKey')

export const getEventRegistration = (eventId, companyId) =>
  http.get(`events/${eventId}/registration/${companyId}`)

export const createEventRegistration = (eventId, eventCompany) =>
  http.post(`/events/${eventId}/register`, {
    ...eventCompany,
  })

export const createEvent = (event) =>
  http.post(`/events/create`, {
    ...event,
  })

export const getEventPackages = (eventId) =>
  http.get(`events/${eventId}/packages`)
