import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { DatePicker, Input, Select } from '@context365/forms'
import { Save } from '@context365/icons'
import { Modal } from '@context365/modals/Modal'
import { Select as MultiSelect, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { useHistory } from 'react-router-dom'
import {
  checkForDupeCompanies,
  createCompany,
  getTierOptions,
} from '../../actions/company'
import { createEvent } from '../../actions/events'
import { getCountries } from '../../actions/fundList'
import {
  CATEGORY_ALLOCATOR,
  CATEGORY_MANAGER,
  CATEGORY_SERVICE_PROVIDER,
} from '../../constants/types/categories'
import http from '../../utils/api'

const NewEventForm = () => {
  const [event, setEvent] = useState({})

  const history = useHistory()

  const [countries, setCountries] = useState([])
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    getCountries().then((res) => setCountries(res.data))
  }, [])

  const handleChange = useCallback(
    (key, value) => {
      setEvent({ ...event, [key]: value })
    },
    [event]
  )

  const handleSave = () => {
    if (isEmpty(event.name) || isNil(event.startDate) || isNil(event.endDate)) {
      setTouched(true)
    } else if (new Date(event.startDate) > new Date(event.endDate)) {
      message.error('Event end date must be after start date')
    } else {
      createEvent(event)
        .then(() => {
          message.success('Event created successfully')
          history.push('/summits')
        })
        .catch(() =>
          message.error('An error ocurred while creating this event')
        )
    }
  }

  return (
    <>
      <div className="bg-white p-4">
        <div className="type-header-md mb-4">Create New Event</div>
        <div className="grid grid-cols-4 gap-4">
          <Input
            className="col-span-2"
            required
            label="Event Name"
            value={event.name}
            errorMessage={touched && isEmpty(event.name) ? 'Required' : null}
            onChange={(e) => handleChange('name', e.target.value)}
          />
          <DatePicker
            className="col-start-1"
            label="Start Date"
            required
            value={event.startDate ? new Date(event.startDate) : null}
            errorMessage={
              touched && isEmpty(event.startDate) ? 'Required' : null
            }
            onChange={(d) => handleChange('startDate', d.toDateString())}
          />
          <DatePicker
            label="End Date"
            required
            value={event.endDate ? new Date(event.endDate) : null}
            errorMessage={touched && isEmpty(event.endDate) ? 'Required' : null}
            onChange={(d) => handleChange('endDate', d.toDateString())}
          />
          <DatePicker
            label="Booth Selection Start Date"
            value={event.boothStartDate ? new Date(event.boothStartDate) : null}
            onChange={(d) => handleChange('boothStartDate', d.toDateString())}
          />
          <DatePicker
            label="Booth Selection End Date"
            value={event.boothEndDate ? new Date(event.boothEndDate) : null}
            onChange={(d) => handleChange('boothEndDate', d.toDateString())}
          />
          <Input
            label="Venue"
            value={event.venue}
            onChange={(e) => handleChange('venue', e.target.value)}
          />
          <Input
            className="col-start-1"
            label="Street Address"
            value={event.address1}
            onChange={(e) => handleChange('address1', e.target.value)}
          />
          <Input
            label="Address 2"
            value={event.address2}
            onChange={(e) => handleChange('address2', e.target.value)}
          />
          <Input
            label="Address 3"
            value={event.address3}
            onChange={(e) => handleChange('address3', e.target.value)}
          />
          <Select.AutoComplete
            className="col-start-1"
            label="Country"
            placeholder="Select country"
            value={event.country}
            onChange={(e) => handleChange('country', e)}
          >
            {({ inputValue }) =>
              inputValue.length > 0
                ? map(
                    countries.filter((a) =>
                      a.name.toLowerCase().includes(inputValue.toLowerCase())
                    ),
                    (c) => (
                      <Select.Option value={c.name}>{c.name}</Select.Option>
                    )
                  )
                : 'Begin typing to search'
            }
          </Select.AutoComplete>
          <Input
            label="City"
            value={event.city}
            onChange={(e) => handleChange('city', e.target.value)}
          />
          <Input
            label="State/Province"
            value={event.state}
            onChange={(e) => handleChange('state', e.target.value)}
          />
          <Input
            label="Zipcode"
            value={event.state}
            onChange={(e) => handleChange('postalCode', e.target.value)}
          />
        </div>
        <div className="mt-8 flex justify-end">
          <Button iconLeft={<Save />} onClick={handleSave} variant="filled">
            Save
          </Button>
        </div>
      </div>
    </>
  )
}

export default NewEventForm
